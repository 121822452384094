export const products = {
    buttonCreate: '+ Add product',

    offCanvas: {
        title:'New Product',
        subTitle:'Configure and name the new product.',
    },

    productsGallery: {
        titleSales: 'Products',
        titleBuyer: 'My Products',
    },

    productCard: {
        ProductCardContentSales: {
            students:'Students',
            modules: 'Modules',
        }
    }
}