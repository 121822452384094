export const availableMenuProduct = {
    settings: {
        name: "Configurações",
        text: "Personalização",
    },
    files: {
        name: "Arquivos",
        text: "Tipos",
    },
    offers: {
        name: "Ofertas",
        text: "Cobrança",
    },
    checkout: {
        name: "Checkout",
        text: "Editor",
    },
    trackingCode: {
        name: "Código de Rastreamento",
        text: "Gerenciador",
    },
    upsellDownsell: {
        name: "Upsell, Downsell",
        text: "Gerenciador",
    },
    coupons: {
        name: "Cupons",
        text: "Retenção",
    },
    affiliation: {
        name: "Afiliados",
        text: "Marketing e revenda",
    },
    membersArea: {
        name: "Área de Membros",
        text: "Membros",
    },
};
