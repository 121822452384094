export const modal = {
    delete: {
        cancel: "I want to go back",
        delete: "I want to delete",

        closeAccount: "Close account",

        title: "Be careful, please",
        description: `Note that this action is not reversible. \n
            Please, confirm that you want to delete this :entity?`,
        confirm_deletion: "Confirm deletion",
    },

    actions: {
        cancel: "Cancel",
        confirm: "Confirm",
    },
};
