export const accountProfile = {
    title: "Perfil",

    form: {
        fullName: {
            label: "Nombre completo (individual)",
            placeholder: "Ingrese su nombre completo",
        },
        email: {
            label: "Correo electrónico",
            placeholder: "Escriba su correo electrónico",
        },
        publicName: {
            label: "Nombre público",
            placeholder: "Ingrese su nombre público",
        },
        website: {
            label: "Sitio web",
            placeholder: "Ingrese su sitio web",
        },
        description: {
            label: "Descripción",
            // placeholder: "Ingrese su descripción",
        },
    },

    sessionTitle: "Datos públicos",

    buttonText: "Guardar ediciones",
}
