export function useLocalStorage() {
  function get(key) {
    let content = localStorage.getItem(key);

    return JSON.parse(content);
  }

  function put(key, content) {
    if (typeof content === "undefined")
      throw new TypeError("You can not put undefined content");

    content = JSON.stringify(content);
    localStorage.setItem(key, content);
  }

  function clean(keyName) {
    localStorage.removeItem(keyName)
  }

  function cleanAll() {
    localStorage.clear();
  }

  return {
    get,
    put,
    clean,
    cleanAll
  };
}