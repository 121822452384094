import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import avatar from "@assets/Icons/avatar-icon.svg";

import { useAuthentication } from "@/hooks/authentication.js";
import { useUserProfile } from "@/hooks/userProfile.js";

import userAccount from "@assets/Icons/user-account.svg";
import userSales from "@assets/Icons/user-sales.svg";
import userSettings from "@assets/Icons/user-settings.svg";
import userPrivacyPolicy from "@assets/Icons/user-privacy-policy.svg";
import userHelp from "@assets/Icons/user-help.svg";
import userLogout from "@assets/Icons/user-logout.svg";
import { DropdownItem, DropdownWrapper } from "@/components/Layout/Dropdown";
import { TextWithIcon } from "@/components/Typography/TextWithIcon";

const UserOptionsDropdownTrigger = () => {
    const { t } = useTranslation();

    const userProfile = useUserProfile().fromLocalStorage();

    const userFullName = userProfile && userProfile.personalProfile.fullName;
    const userType =
        userProfile && userProfile.isCreator ? t("header.dropdown.salesPerson") : t("header.dropdown.student");

    return (
        <div className="flex flex-row gap-2 items-center">
            <img
                className="w-10 h-10 rounded-full"
                src={userProfile.hasAvatar() ? userProfile.personalProfile.avatar : avatar}
                alt="avatar"
            />
            <p className="flex flex-col text-start gap-0">
                <span className="text-md font-bold leading-tight">{userFullName}</span>
                <span className="text-xs text-gray-500 leading-tight">{userType}</span>
            </p>
        </div>
    );
};

const UserOptionsDropdown = () => {
    const auth = useAuthentication();
    const { t } = useTranslation();

    const navigate = useNavigate();

    const menuItems = [
        {
            image: userAccount,
            name: "header.dropdown.myAccount",
            action: "/account/profile",
        },
        {
            image: userSales,
            name: "header.dropdown.mySales",
            action: "/sales",
        },
        {
            image: userSettings,
            name: "header.dropdown.configuration",
            action: "/account/personal-data",
        },
        {
            image: userPrivacyPolicy,
            name: "header.dropdown.privacyPolicy",
            action: "/privacy-policy",
            anotherTab: true,
        },
        {
            image: userHelp,
            name: "header.dropdown.help",
            action: "",
        },
    ];

    const doLogout = () => auth.logout().finally(() => navigate("/"));

    return (
        <DropdownWrapper
            icon={avatar}
            title="ProfileMenu"
            trigger={<UserOptionsDropdownTrigger />}
        >
            {menuItems.map(menu => (
                <Link
                    key={menu.name}
                    to={menu.action}
                    rel={menu.anotherTab ? "noopener noreferrer" : ""}
                    target={menu.anotherTab ? "_blank" : "_self"}
                >
                    <DropdownItem>
                        <TextWithIcon
                            icon={menu.image}
                            className="font-normal"
                        >
                            {t(menu.name)}
                        </TextWithIcon>
                    </DropdownItem>
                </Link>
            ))}

            <Link
                to={"#"}
                onClickCapture={doLogout}
            >
                <DropdownItem>
                    <TextWithIcon
                        icon={userLogout}
                        className="font-normal text-red-500"
                    >
                        {t("header.dropdown.exit")}
                    </TextWithIcon>
                </DropdownItem>
            </Link>
        </DropdownWrapper>
    );
};

export default UserOptionsDropdown;
