const HeaderWithDivider = ({children, textSize = 'md', className, showDivider = true}) => {

  return (
    <div className='flex flex-col w-full gap-2'>
      <span className={`text-${textSize} font-bold ${className}`}>
          {children}
      </span>

      {showDivider &&
        <div className='border border-gray-100'></div>
      }
    </div>
  )
}

export default HeaderWithDivider;