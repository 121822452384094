export const accountProfile = {
    title: "Perfil",

    form: {
        fullName: {
            label: "Nome completo (individual)",
            placeholder: "Digite seu nome completo",
        },
        email: {
            label: "E-mail",
            placeholder: "Digite seu e-mail",
        },
        publicName: {
            label: "Nome público",
            placeholder: "Digite seu nome público",
        },
        website: {
            label: "Website",
            placeholder: "Digite seu website",
        },
        description: {
            label: "Descrição",
            // placeholder: "Digite sua descrição",
        },
    },

    sessionTitle: "Dados públicos",

    buttonText: "Salvar edições",
};
