/**
 * Check if a given value is not null
 *
 * @param {any} value what to check null against
 * @returns boolean false if value is null, otherwise true
 */
export const isNotNull = value => value != null && value != undefined;

/**
 * Check when a given string is empty or not
 *
 * @param {any} value string to check if empty
 * @returns {Boolean} based on string content (true if not empty)
 */
export const isNotEmpty = value => value.toString() != "";

/**
 * Reduce an array to object.
 * ⚠️ NOTE - MAYBE this code is redundant, but is the best I could do now
 */
function reduceArray(previous, current) {
  const toObject = ([field, value]) => ({ [field]: value });

  if (Array.isArray(previous)) {
    previous = toObject(previous);
  }

  return { ...previous, ...toObject(current) };
}

/**
 * Convert a given array to object, including it's children
 *
 * @param {array} toConvert array to reduce into object
 * @returns {Object} toCovert object representation
 */
export const arrayToObject = toConvert => toConvert.reduce(reduceArray);

/**
 * Defines if all fields on a specific form data are not empty
 *
 * @param {Object} formData form data to validate
 * @returns {Boolean} true when there is none empty string
 */
export function isRequiredFieldsFilled(formData) {
  return Object.values(formData).filter(element => element === "").length === 0;
}

/**
 * Filter null values from a specific object
 *
 * @param {Object} data object to filter
 * @returns {Object} new object without empty or null values
 */
export function filterNullValues(data) {
  return Object.entries(data).filter(([_, value]) => isNotNull(value) && isNotEmpty(value));
}

/**
 * A simple wrapper to run a ternary statement against a condition and return
 * the required value.
 *
 * @param {Boolean} condition determines which value will be returned
 * @param {*} valueIf Value when condition is true
 * @param {*} valueElse Value when condition is false
 * @returns {*} `valueIf` when `condition` is **true**, otherwise `valueElse`
 */
export function ifElse(condition, valueIf, valueElse) {
  return condition ? valueIf : valueElse;
}
