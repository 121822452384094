import translator from "@assets/Icons/translator.svg";
import { useChangeLanguage } from "@/hooks/useChangeLanguage";
import { DropdownItem, DropdownWrapper } from "@/components/Layout/Dropdown";

const LanguageSelectorDropdown = () => {
    const { changeLanguage } = useChangeLanguage();

    const AvailableLanguages = [
        { id: "en", label: "🇺🇸 English" },
        { id: "es", label: "🇪🇸 Spanish" },
        { id: "pt", label: "🇧🇷 Portuguese" },
    ];

    return (
        <DropdownWrapper
            icon={translator}
            title="Change language"
        >
            {AvailableLanguages.map(element => (
                <span
                    className="text-sm"
                    key={element.id}
                    onClickCapture={() => changeLanguage(element.id)}
                >
                    <DropdownItem>{element.label}</DropdownItem>
                </span>
            ))}
        </DropdownWrapper>
    );
};

export default LanguageSelectorDropdown;
