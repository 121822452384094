import {useEffect} from "react";

const useCloseDropdown = (reference, onClickOutside) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (reference.current && !reference.current.contains(event.target))
        onClickOutside();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    }
  }, [reference, onClickOutside])
}

export default useCloseDropdown;