export const header = {
    salutation: "Hello",
    text: "Welcome back.",
    search: "Search...",

    notification:{

    },
    
    message: {

    },

    dropdown: {
        salesPerson: "Sales Person",
        student: "Student",
        myAccount: "My account",
        mySales: "My sales",
        configuration: "Configuration",
        privacyPolicy: "Privacy Policy",
        help: "Help",
        exit: "Exit",
    }
}