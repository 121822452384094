import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { LoadingPageFallback } from "@/LoadingPageFallback";
import { LateralMenu } from "@/layouts/Partials/LateralMenu";
import { SuperiorHeaderBar } from "@/layouts/Partials/SuperiorHeader.jsx";

import { HeaderWithSubtitle } from "@/components/Typography/HeaderWithSubtitle";

import { useUserProfile } from "@/hooks/userProfile";
import OmniSearchBar from "@/layouts/Partials/OmniSearchBar";
import MessagesDropdown from "@/layouts/AddOns/MessagesDropdown";
import UserOptionsDropdown from "@/layouts/AddOns/UserOptionsDropdown";
import NotificationsDropdown from "@/layouts/AddOns/NotificationsDropdown";
import LanguageSelectorDropdown from "@/layouts/AddOns/LanguageSelectorDropdown";

const XTreedCreatorLayout = ({ isLoading = false, children }) => {
    const { t } = useTranslation();

    const user = useUserProfile().fromLocalStorage();

    const displayName = useMemo(() => user.personalProfile?.fullName || user.email || "", [user]);

    if (isLoading) return <LoadingPageFallback />;

    return (
        <div className="flex flex-row flex-grow bg-slate-100 h-screen">
            <LateralMenu />

            <div className="flex flex-col w-full">
                <SuperiorHeaderBar>
                    <div className="flex flex-row w-full items-center justify-between px-4 py-3 gap-5">
                        <HeaderWithSubtitle
                            title={`${t("header.salutation")}, ${displayName}`}
                            subtitle={t("header.text")}
                        />

                        <div className="flex-grow hidden lg:flex gap-1 items-center justify-between">
                            <OmniSearchBar />

                            <LanguageSelectorDropdown />

                            {/*<MessagesDropdown />*/}

                            {/*<NotificationsDropdown />*/}

                            <UserOptionsDropdown />
                        </div>
                    </div>
                </SuperiorHeaderBar>

                <main className="overflow-y-scroll overflow-x-hidden pb-20 lg:pt-0 lg:max-w-full lg:pb-0 flex flex-col flex-grow">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default XTreedCreatorLayout;
