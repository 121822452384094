export const header = {
    salutation: "Hola",
    text: "Bienvenido de nuevo.",
    search: "Buscar...",

    notification: {

    },
    
    message: {

    },

    dropdown: {
        salesPerson: "Vendedor",
        student: "Estudiante",
        myAccount: "Mi cuenta",
        mySales: "Mis ventas",
        configuration: "Configuración",
        privacyPolicy: "Política de privacidad",
        help: "Ayuda",
        exit: "Salir",
    }
}
