// Routes.js
import React from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { PublicRoutes, CreatorAreaRoutes, ClassroomRoutes } from "@/routes";

import { DefaultError } from "@/pages/DefaultError.jsx";
import { ProtectedRoute } from "@comp/ProtectedRoute";

class AppRouter extends React.Component {
  #routeSettings = { v7_startTransition: true }

  constructor(props) {
    super(props);

    // Enable/disable classroom features based on current environment
    this.enableClassroom = import.meta.env.VITE_ENABLE_CLASSROOM == 'true';

    // Build first route batch based on RoutesCollection.js
    this.routes = this.#buildRoutes();

    // Build our entire route system, first on build then on processing it's properties
    this.routes = createBrowserRouter(this.routes);
  }

  /**
   * Build our routes based on current environment snapshot, feeding
   * [this.route] with public and protected routes
   */
  #buildRoutes() {
    // TODO If possible, we should verify user's role and concat only creator or student routes
    let routes = []
      .concat(this.#processRouteCollection(PublicRoutes))
      .concat(this.#processRouteCollection(CreatorAreaRoutes, true));

    if (this.enableClassroom)
      routes = routes.concat(this.#processRouteCollection(ClassroomRoutes, true));

    return routes;
  }

  /**
   * Process each route to apply its customizations based on properties
   * available when declaring inside [@/data/RoutesCollection.js].
   * 
   * For each row we can handle errors, protec routes and modify any
   * other required property before add it to React route stack
   */
  #processRouteCollection(routes, protect = false) {
    return routes.map(route => {
      route = this.#addDefaultErrorHandler(route)

      if (protect)
        route = this.#protectSingleRoute(route)

      return route;
    });
  }

  /**
   * Add default error handler element to current route
   * @param {Object} route current route to handle
   */
  #addDefaultErrorHandler(route) {
    route.errorElement = <DefaultError />

    return route;
  }

  /**
   * Protect current route whenever necessary
   * @param {Object} route current route to handle
   */
  #protectSingleRoute(route) {
    if (route.protected)
      route.element = <ProtectedRoute element={route.element} />

    return route;
  }

  /**
   * Render current RouteProvider component to React stack
   */
  render() {
    return <RouterProvider router={this.routes} future={this.#routeSettings} />
  }
}

export default AppRouter;
