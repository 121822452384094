import { useState, useRef, useMemo } from "react";
import closeDropdown from "@/hooks/useCloseDropdown.js";

export const DropdownItem = ({ children }) => {
    return (
        <span className="flex cursor-pointer rounded flex-row py-3 px-3 hover:bg-gray-100 transition duration-200">
            {children}
        </span>
    );
};

export const DropdownTrigger = ({ icon, title, onClick, className = "" }) => {
    return (
        <img
            className={`cursor-pointer p-2 ${className}`}
            src={icon}
            alt={title}
            onClick={onClick}
        />
    );
};

export const DropdownContent = ({ children, show, size }) => {
    const hidden = "opacity-0 scale-0 duration-75";

    const shown = "opacity-100 scale-100 duration-75";

    const dropdownSize = useMemo(() => {
        if (size == "md") return "max-w-md";
        if (size == "lg") return "max-w-lg";
        if (size == "xl") return "max-w-xl";

        return "max-w-56";
    }, [size]);

    return (
        <div
            className={`
                flex flex-col absolute top-full z-100 right-0 overflow-clip
                bg-white shadow-lg ${show ? shown : hidden} rounded-lg 
                transform-gpu will-change-transform origin-top-right
                h-auto min-w-56 w-screen ${dropdownSize}
            `}
        >
            {children}
        </div>
    );
};

export const DropdownWrapper = ({ icon, title, children, trigger, size }) => {
    const [showDropdown, setDropdownState] = useState(false);

    const wrapperRef = useRef(null);

    closeDropdown(wrapperRef, () => setDropdownState(false));

    const toggleDropdownState = () => setDropdownState(!showDropdown);

    return (
        <div
            className="relative"
            ref={wrapperRef}
        >
            {!trigger && (
                <DropdownTrigger
                    icon={icon}
                    title={title}
                    className="hover:bg-gray-100 rounded-lg transition duration-200"
                    onClick={toggleDropdownState}
                />
            )}

            {trigger && (
                <button
                    className="hover:bg-gray-100 p-2 rounded-lg transition duration-200"
                    onClickCapture={toggleDropdownState}
                >
                    {trigger}
                </button>
            )}

            <DropdownContent
                show={showDropdown}
                size={size}
            >
                {children}
            </DropdownContent>
        </div>
    );
};
