export const coupons = {
    title: "Cupones",

    page: {
        search: "Buscar productos",
        buttonCreate: "Agregar cupón",
    },

    EmptyState: {
        label: "Aún no has creado ningún descuento",
        content: "Crea descuentos para aumentar tus ventas",
    },

    dataTable: {
        headers: {
            name: "Nombre",
            coupon: "Cupón",
            code: "Código",
            status: "Estado",
            action: "Acción",
        },
    },

    offCanvas: {
        title: "Nuevo cupón",
        subTitle: "Configura un cupón de descuento y aumenta las conversiones de tu tienda, atrae nuevos compradores y fomenta la finalización de la compra."
    },

    createCoupon: {
        switchText: 'Establece el estado inicial de tu cupón.',
        name: {
            label: 'Nombre',
            placeholder: 'Ingresa un nombre',
        },
        codeCoupon: {
            label: 'Código del cupón',
            placeholder: 'Cupón',
        },

        text: 'Reglas para aplicar el cupón',

        rules: {
            value: 'Valor en US$',
            percent: 'Porcentaje en %',
        },
        discount: {
            label: 'Descuento desde',
            placeholder: 'US$ 0,00',
        },
        dueDate: {
            switchText: 'No vencido',
            label: 'Vence en',
            placeholder: '00/00/0000',
        },
        noLimit: {
            switchText: 'No hay límite',
            label: 'Límite de uso',
            placeholder: '0',
        },

        buttonCancel: 'Cancelar',

        buttonAction: 'Agregar cupón',
    },
    
    editCoupon: {
        title: 'Editar el cupón',
        subTitle: 'Edita la información de la oferta según lo desees.',
        buttonEdit: 'Editar cupón',
    },

    DeleteCoupon: {
        title: 'Eliminar cupón',

        label: '¿Estás seguro de que deseas eliminar el cupón',
    }
}
