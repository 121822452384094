export const configurationProduct = {
    title: "Configuración",

    textSwitch: "Gestiona si el producto estará activo para ventas o no",

    form: {
        nameProduct: {
            label: "Nombre del producto",
            placeholder: "Ingrese un nombre para el producto",
        },
        baseCurrency: {
            label: "Moneda base",
            placeholder: "Seleccione su moneda base aquí",
        },
        price: {
            label: "Precio",
            placeholder: "US$ 0,00",
        },
        briefDescription: {
            label: "Descripción breve",
            placeholder: "Describa su producto en pocas palabras",
        },
    },

    buttonText: 'Guardar',
}
