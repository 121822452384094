export const trackingCode = {
    title: "Código de Rastreamento",

    search: "Buscar código de rastreamento",
    buttonCreate: "+ Adicionar código de rastreamento",

    offCanvas: {
        title: "Registrar Código de Rastreamento",
        subTitle: "Selecione a plataforma que você deseja registrar.",
    },

    createTrackingCode: {
        switchText: "Defina o estado inicial do seu código de rastreamento.",

        name: {
            label: "Nome",
            placeholder: "Digite seu nome",
        },
        code: {
            label: "Código",
            placeholder: "Exemplo F-NGANOU",
        },

        settings: {
            title: "Configurar eventos do código de rastreamento",
            subTitle: "Registrando e otimizando conversões.",

            switchCheckout: "Iniciar checkout (begin_checkout)",
            switchPayment: "Adicionar detalhes de pagamento (add_payment_info)",
            switchPurchase: "Compra",
            switchTrigger: "Acionar evento 'Compra' ao gerar fatura",
            switchPix: "Acionar evento 'Compra' ao gerar pix"
        },

        buttonCancel: "Cancelar",
        buttonContinue: "Continuar",
        buttonAction: "Adicionar",
    },

    EmptyState: {
        label: "Você ainda não criou nenhum código de rastreamento",
        content: "Crie códigos de rastreamento",
    },

    dataTable: {
        headers: {
            name: "Nome",
            id: "ID",
            platform: "Plataforma",
            status: "Status",
            action: "Ação",
        },

        editTrackingCode: {
            title: "Editar o código de rastreamento",
            subTitle: "Edite as informações do código de rastreamento conforme desejado.",
            buttonEdit: "Editar",
        }

    },

    deleteTrackingCode: {
        title: "Excluir Código de Rastreamento",
        label: "Você tem certeza de que deseja excluir o código de rastreamento?",
    }
}
