export const ChartMarketingIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.1668 11.9163V8.24967C20.1668 3.66634 18.3335 1.83301 13.7502 1.83301H8.25016C3.66683 1.83301 1.8335 3.66634 1.8335 8.24967V13.7497C1.8335 18.333 3.66683 20.1663 8.25016 20.1663H11.9168"
                stroke="inherit"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.71924 13.2828L8.90091 10.4503C9.21258 10.0469 9.79008 9.97361 10.1934 10.2853L11.8709 11.6053C12.2743 11.9169 12.8517 11.8436 13.1634 11.4495L15.2809 8.71777"
                stroke="inherit "
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.8565 14.5013L18.1131 15.0238C18.2415 15.2805 18.5623 15.5188 18.8464 15.5738L19.1948 15.6288C20.2398 15.803 20.4873 16.573 19.7356 17.3338L19.4148 17.6546C19.2039 17.8746 19.0848 18.2963 19.149 18.5897L19.1948 18.7822C19.479 20.0472 18.8098 20.533 17.7098 19.873L17.4714 19.7355C17.1873 19.5705 16.729 19.5705 16.4448 19.7355L16.2064 19.873C15.0973 20.5421 14.4281 20.0472 14.7214 18.7822L14.7673 18.5897C14.8314 18.2963 14.7123 17.8746 14.5015 17.6546L14.1806 17.3338C13.429 16.573 13.6764 15.803 14.7214 15.6288L15.0698 15.5738C15.3448 15.528 15.6748 15.2805 15.8031 15.0238L16.0598 14.5013C16.5548 13.5022 17.3615 13.5022 17.8565 14.5013Z"
                stroke="inherit "
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
