export const availableMenuAccount = {

    profile: {
        name: "Profile",
        text: "Add text",
    },
    personalData: {
        name: "Personal Data",
        text: "Add text"
    },
    documents: {
        name: "Documents",
        text: "Add text",
    },
    financialData: {
        name: "Financial Data",
        text: "Add text",
    },
    security: {
        name: "Security",
        text: "Add text",
    },
}