export const products = {
    buttonCreate: '+ Agregar producto',

    offCanvas: {
        title: 'Nuevo Producto',
        subTitle: 'Configura y nombra el nuevo producto.',
    },

    productsGallery: {
        titleSales: 'Productos',
        titleBuyer: 'Mis Productos',
    },

    productCard: {
        ProductCardContentSales: {
            students: 'Estudiantes',
            modules: 'Módulos',
        }
    }
}
