export const availableMenuProduct = {

    settings: {
        name: "Configuraciones",
        text: "Personalización",
    },
    files: {
        name: "Archivos",
        text: "Tipos"
    },
    offers: {
        name: "Ofertas",
        text: "Carga",
    },
    checkout: {
        name: "Proceso de compra",
        text: "Editor",
    },
    trackingCode: {
        name: "Código de seguimiento",
        text: "Administrador",
    },
    upsellDownsell: {
        name: "Upsell, Downsell",
        text: "Administrador",
    },
    coupons: {
        name: "Cupones",
        text: "Retención",
    },
    affiliation: {
        name: "Afiliación",
        text: "Marketing y reventa",
    },
    membersArea: {
        name: "Área de miembros",
        text: "Miembros",
    },
}
