import search from "@assets/Icons/search-icon.svg";
import TextField from "@comp/Form/TextField.jsx";
import { useTranslation } from "react-i18next";

const OmniSearchBar = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-auto justify-center px-20">
            <div className="flex w-1/2 items-center gap-5 transition-all duration-500 focus-within:w-full">
                <TextField
                    placeholder={t("header.search")}
                    leadingIcon={{ image: search }}
                />
            </div>
        </div>
    );
};

export default OmniSearchBar;
