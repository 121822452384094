export const notification = {
    title: "Notificaciones",
    subTitle: "Todas tus notificaciones",

    search: "Buscar notificaciones",

    atualization: {
        title: "Actualización de datos",
        subTitle: "No olvides participar en nuestro",
    },
    announcement: {
        title: "Anuncio de nueva integración",
        subTitle: "Nuestro Panel de Control de Gestión",
    },
    newRequest: {
        title: "Nueva solicitud",
        subTitle: "James Eusébio realizó un nuevo pedido para Blue",
    },

    buttonText: "Mostrar todas las notificaciones",
}
