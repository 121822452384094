import { createContext, useCallback, useState } from "react";
import { useUserProfile } from "@/hooks/userProfile";

export const AuthenticationContext = createContext();

export const AuthenticationProvider = ({ children }) => {
  /** Enable our component to use fromLocalStorage hook method */
  const { fromLocalStorage } = useUserProfile();

  /** Get user from local storage to handle our authentication */
  const [user] = useState(fromLocalStorage());

  /** Defines if our user is authenticated according to User Storage */
  const [isAuthenticated, setIsAuthenticated] = useState(user.id !== null);

  /**
   * Returns our context provider with all these calculated values to check
   * inside {ProtectedRoutes} component
   */
  return (
    <AuthenticationContext.Provider value={{ user, isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
