export const configurationProduct = {
    title: "Configuration",

    textSwitch: "Manage whether or not the product will be active for sales",

    form: {
        nameProduct: {
            label: "Product's name",
            placeholder: "Enter a name for the product",
        },
        baseCurrency: {
            label: "Base Currency",
            placeholder: "Select your base currency here",
        },
        price: {
            label: "Price",
            placeholder: "US$ 0,00",
        },
        briefDescription: {
            label: "Brief description",
            placeholder: "Describe your product in a few words",
        },
    },

    buttonText: 'Save',
}