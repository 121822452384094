export const notification = {
    title: "Notificações",
    subTitle: "Todas as suas notificações",

    search: "Pesquisar notificações",

    atualization: {
        title: "Atualização de dados",
        subTitle: "Não se esqueça de participar do nosso",
    },
    announcement: {
        title: "Anúncio de nova integração",
        subTitle: "Nosso Painel de Controle de Gestão",
    },
    newRequest: {
        title: "Nova solicitação",
        subTitle: "James Eusébio fez um novo pedido de Blue",
    },

    buttonText: "Mostrar todas as notificações",
}
