export const configurationProduct = {
    title: "Configuração",

    textSwitch: "Gerencie se o produto estará ativo para vendas ou não",

    form: {
        nameProduct: {
            label: "Nome do produto",
            placeholder: "Digite um nome para o produto",
        },
        baseCurrency: {
            label: "Moeda Base",
            placeholder: "Selecione sua moeda base aqui",
        },
        price: {
            label: "Preço",
            placeholder: "R$ 0,00",
        },
        briefDescription: {
            label: "Descrição breve",
            placeholder: "Descreva seu produto em poucas palavras",
        },
    },

    buttonText: 'Salvar',
};
