import { lazy } from "react";

const AreaUserBuyer = lazy(() => import('@/pages/AreaUserBuyer/AreaUserBuyerPage.jsx'));
const MyProductsPage = lazy(() => import('@/pages/MyProductsPage.jsx'));

export const ClassroomRoutes = [
  {
    path: '/areaUser',
    element: <AreaUserBuyer />
  },
  {
    path: '/myproducts',
    element: <MyProductsPage />
  },
];
