export const notificationSettings = {
    title: "Configuração de Notificações",
    subTitle: "Defina separadamente suas preferências de notificações por e-mail",
    salesManagement: {
        title: "Gestão de Vendas",
        description: "Ativar/desativar a funcionalidade de gestão de vendas no painel de controle",
    },
    assessments: {
        title: "Avaliações",
        description: "Ativar/desativar notificações para avaliações",
    },

    affiliates: {
        title: "Afiliados",
        description: "Ativar/desativar notificações de novas informações ou alterações sobre afiliados",
    },
    News: {
        title: "Notícias",
        description: "Ativar/desativar notificações de notícias e atualizações da empresa",
    },

    buttonText: "Salvar alterações",
}
