export const availableMenuProduct = {

    settings: {
        name: "Settings",
        text: "Customization",
    },
    files: {
        name: "Files",
        text: "Types"
    },
    offers: {
        name: "Offers",
        text: "Charge",
    },
    checkout: {
        name: "Checkout",
        text: "Editor",
    },
    trackingCode: {
        name: "Tracking Code",
        text: "Manager",
    },
    upsellDownsell: {
        name: "Upsell, Downsell",
        text: "Manager",
    },
    coupons: {
        name: "Coupons",
        text: "Retention",
    },
    affiliation: {
        name: "Affiliation",
        text: "Marketing and resale",
    },
    membersArea: {
        name: "Members area",
        text: "Members",
    },
}