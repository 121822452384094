import ReactDOM from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import * as Sentry from "@sentry/react";
import { XTreedApp } from "./XTreedApp";

/**
 * Integrate our Sentry javascript plugin
 */

// console.log(import.meta);

Sentry.init({
    dsn: "https://1c820895515209aecdd6465d706e8791@o459069.ingest.us.sentry.io/4507261998399488",
    environment: "production",
    denyUrls: "*frontend-local.xtreed.com:3000/*",
    integrations: [],
});

/**
 * Initialize i18n React plugin
 */
import "@lang/initialization.js";

/**
 * Import custom stylesheets rules from index.css
 */
import "./index.css";

/**
 * Get root HTML element to effectively create the React Application
 * @type {HTMLElement}
 */
const rootContainer = document.getElementById("root");
ReactDOM.createRoot(rootContainer).render(<XTreedApp />);
