export const ChartIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.75731 9.35156V15.64"
                stroke="inherit"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M11.0351 6.3418V15.6393"
                stroke="inherit"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                d="M15.2431 12.6738V15.6393"
                stroke="inherit"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />

            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.2954 1.83301H6.70492C3.71048 1.83301 1.8335 3.95242 1.8335 6.95273V15.0466C1.8335 18.0469 3.70175 20.1663 6.70492 20.1663H15.2954C18.2986 20.1663 20.1668 18.0469 20.1668 15.0466V6.95273C20.1668 3.95242 18.2986 1.83301 15.2954 1.83301Z"
                stroke="inherit"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
