import { useState } from "react";
import NavigationMenu from "@layouts/Partials/NavigationMenu";
import { XTreedMainLogo } from "@/components/Layout/XTreedMainLogo";

import TriggerMenuIcon from "@/assets/Icons/modulo-icon.svg";

export const SuperiorHeaderBar = ({ children }) => {
    const [hideMobileMenu, toggleMobileMenu] = useState(true);

    return (
        <div className="flex flex-col justify-start z-10 bg-white sticky top-0">
            <div className="flex flex-col gap-0">
                <div className="lg:hidden border border-b-1 flex flex-row justify-between border-gray-200 pe-3">
                    <XTreedMainLogo extended={true} />

                    <button
                        type="button"
                        className="p-1"
                        onClick={() => toggleMobileMenu(!hideMobileMenu)}
                    >
                        <img
                            className="relative w-6 h-6"
                            src={TriggerMenuIcon}
                        />
                    </button>
                </div>

                <div
                    hidden={hideMobileMenu}
                    aria-hidden={hideMobileMenu}
                    className="relative transition duration-300 transform-gpu aria-hidden:h-px h-auto aria-hidden:scale-y-0"
                >
                    <NavigationMenu />
                </div>

                {children}
            </div>
        </div>
    );
};
