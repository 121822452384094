import { lazy } from "react";

const WelcomePlatform = lazy(() => import("@/pages/WelcomePage"));
const CreateProductPage = lazy(() => import("@/pages/NewProduct/CreateProductPage"));
const SettingsNotification = lazy(() => import("@/pages/Notifications/NotificationsSettingsPage"));
const Reports = lazy(() => import("@/pages/reports"));
const Marketing = lazy(() => import("@/pages/marketing"));
const AccountPage = lazy(() => import("@/pages/Account/AccountPage.jsx"));
const Profile = lazy(() => import("@/pages/Profile/ProfilePage.jsx"));
const PersonalData = lazy(() => import("@/pages/PersonalData/PersonalDataPage.jsx"));
const SecurityPage = lazy(() => import("@/pages/Security/SecurityPage.jsx"));
const FinancialDataPage = lazy(() => import("@/pages/FinancialData/FinancialDataPage.jsx"));
const DocumentsPage = lazy(() => import("@/pages/Documents/DocumentsPage.jsx"));
const Notification = lazy(() => import("@/pages/Notifications/ViewNotificationsPage"));
const Dashboard = lazy(() => import("@/pages/Dashboard/DashboardPage"));
const Sales = lazy(() => import("@/pages/sales"));
const FinancesPage = lazy(() => import("@/pages/Finance/FinancesPage.js"));
const ProductsGalleryPage = lazy(() => import("@/pages/ProductsGalleryPage"));
const Product = lazy(() => import("@/pages/Product/Product.jsx"));
const FilesPage = lazy(() => import("@/pages/Files/FilesPage.jsx"));
const OffersPage = lazy(() => import("@/pages/Offers/OffersPage.jsx"));
const CheckoutSettingsPage = lazy(() => import("@/pages/CheckoutSettings/CheckoutSettingsPage.jsx"));
const TrackingCodesPage = lazy(() => import("@/pages/TrackingCodes/TrackingCodesPage.jsx"));
const UpsellDownsellPage = lazy(() => import("@/pages/UpsellDownsell/UpsellDownsellPage.jsx"));
const CouponsPage = lazy(() => import("@/pages/Coupons/CouponsPage.jsx"));
const AffiliationPage = lazy(() => import("@/pages/Affiliation/AffiliationPage.jsx"));
const AreaMembersPage = lazy(() => import("@/pages/AreaMembers/AreaMembersPage.jsx"));
const ConfigurationProductPage = lazy(() => import("@/pages/ConfigurationProduct/ConfigurationProductPage.jsx"));
const ConfigurationPage = lazy(() => import("@/pages/ConfigurationPage.jsx"));

export const CreatorAreaRoutes = [
    {
        path: "/welcome",
        element: <WelcomePlatform />,
    },
    {
        path: "/createproduct",
        element: <CreateProductPage />,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
    },
    {
        path: "/products",
        element: <ProductsGalleryPage />,
    },
    {
        path: "/sales",
        element: <Sales />,
    },
    {
        path: "/financial",
        element: <FinancesPage />,
    },
    {
        path: "/reports",
        element: <Reports />,
    },
    {
        path: "/marketing",
        element: <Marketing />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },
    {
        path: "/notification",
        element: <Notification />,
    },
    {
        path: "/configuration",
        element: <ConfigurationPage />,
    },
    {
        path: "/notification-settings",
        element: <SettingsNotification />,
    },
    {
        path: "/account",
        element: <AccountPage />,
        children: [
            {
                path: "profile",
                element: <Profile />,
            },
            {
                path: "personal-data",
                element: <PersonalData />,
            },
            {
                path: "documents",
                element: <DocumentsPage />,
            },
            {
                path: "financial-data",
                element: <FinancialDataPage />,
            },
            {
                path: "security",
                element: <SecurityPage />,
            },
        ],
    },
    {
        path: "/product/:productId",
        element: <Product />,
        children: [
            {
                index: true,
                element: <ConfigurationProductPage />,
            },
            {
                path: "files",
                element: <FilesPage />,
            },
            {
                path: "offers",
                element: <OffersPage />,
            },
            {
                path: "checkout-settings",
                element: <CheckoutSettingsPage />,
            },
            {
                path: "tracking-codes",
                element: <TrackingCodesPage />,
            },
            {
                path: "upsell-downsell",
                element: <UpsellDownsellPage />,
            },
            {
                path: "coupons",
                element: <CouponsPage />,
            },
            {
                path: "affiliation",
                element: <AffiliationPage />,
            },
            {
                path: "area-members",
                element: <AreaMembersPage />,
            },
        ],
    },
];
