export const personalData = {
    titleData: 'Dados Pessoais',

    menu: {
        pf: 'Pessoa Física',
        pj: 'Pessoa Jurídica',
        mei: 'MEI',
    },

    pf: {
        cpf: 'CPF',
        dateofbirth: 'Data de nascimento',
        phone: 'Telefone',
    },

    pj: {
        cnpj: 'CNPJ',
        cpf: 'CPF',
        phone: 'Telefone',
    },

    mei: {
        cpf: 'CPF',
        cnpj: 'CNPJ',
        dateofbirth: 'Data de nascimento',
        phone: 'Telefone',
    },

    titleAddress: 'Endereço',

    address: {
        cep: {
            label: 'CEP',
            placeholder: 'Digite seu CEP'
        },
        address: {
            label: 'Endereço',
            placeholder: 'Digite seu Endereço'
        },
        number: {
            label: 'Número',
            placeholder: 'Digite seu Número'
        },
        complement: {
            label: 'Complemento',
            placeholder: 'Digite seu Complemento'
        },
        neighborhood: {
            label: 'Bairro',
            placeholder: 'Digite seu Bairro'
        },
        city: {
            label: 'Cidade',
            placeholder: 'Digite sua Cidade'
        },
        country: {
            label: 'País de origem',
            placeholder: 'Digite seu País de origem'
        },
        state: {
            label: 'Estado',
            placeholder: 'Digite seu Estado'
        },
    },

    buttonText: 'Salvar alterações',
}
