export const notificationSettings = {
    title: "Notifications configuration",
    subTitle: "Separately set your email notification preferences",
    salesManagement: {
        title: "Sales management",
        description: "Activate/deactivate the sales management functionality in the control panel",
    },
    assessments: {
        title: "Assessments",
        description: "Enable/disable notifications for reviews",
    },

    affiliates: {
        title: "Affiliates",
        description: "Enable/disable notifications of new affiliate information or changes",
    },
    News: {
        title: "News",
        description: "Enable/disable company news and update notifications",
    },

    buttonText: "Save editions",
}