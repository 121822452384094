import { HomeOutline } from "@assets/Icons/HomeOutlineIcon.jsx";
import { ShoppingCartIcon } from "@assets/Icons/ShoppingCartIcon.jsx";
import { BriefcaseIcon } from "@assets/Icons/BriefcaseIcon.jsx";
import { DollarSign } from "@assets/Icons/DolarSignIcon.jsx";
import { ChartIcon } from "@assets/Icons/ChartIcon.jsx";
import { ChartMarketingIcon } from "@assets/Icons/ChartMarketingIcon.jsx";

import Account from "@assets/Icons/user-account.svg";
import Sales from "@assets/Icons/user-sales.svg";
import UserSettings from "@assets/Icons/user-settings.svg";
import PrivacyPolicy from "@assets/Icons/user-privacy-policy.svg";

export const AvailableMenus = [
    {
        id: 0,
        icon: <HomeOutline />,
        name: "availableMenus.dashboard",
        target: "/dashboard",
    },
    {
        id: 1,
        icon: <ShoppingCartIcon />,
        name: "availableMenus.sales",
        target: "/sales",
    },
    {
        id: 2,
        icon: <BriefcaseIcon />,
        name: "availableMenus.products",
        target: "/products",
    },
    {
        id: 3,
        icon: <DollarSign />,
        name: "availableMenus.financial",
        target: "/financial",
    },
    {
        id: 4,
        icon: <ChartIcon />,
        name: "availableMenus.reports",
        target: "/reports",
    },
    {
        id: 5,
        icon: <ChartMarketingIcon />,
        name: "availableMenus.marketing",
        target: "/marketing",
    },
];

export const UserProfileMenu = [
    {
        id: 0,
        image: Account,
        name: "my-account",
        navigate: "/account/profile",
    },
    {
        id: 1,
        image: Sales,
        name: "my-sales",
        navigate: "/sales",
    },
    {
        id: 2,
        image: UserSettings,
        name: "configuration",
        navigate: "/configuration",
    },
    {
        id: 3,
        image: PrivacyPolicy,
        name: "privacy-policy",
        navigate: "/privacy-policy",
    },
];

export const WebsiteMenu = [
    { label: "Inicio", target: "/" },
    { label: "Recursos", target: "#resources" },
];
