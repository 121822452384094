export const offers = {
    title: "Offers",

    search: "Search offers...",
    buttonText: "Add Offers",

    offCanvas: {
        title: "Create offer",
        subTitle: "Configure and name the new offer.",
    },

    createOffer: {
        switchText: "Is this offer active?",

        titleType: "Select the type of offer",

        type: {
            singlePrice: "Single price",
            subscription: "Subscription ",
        },

        name: {
            label: "Offer name",
            placeholder: "Enter the name of the offer",
        },

        freeState: {
            text: "Activate free offer",
            warningFree:
                "Free products are a great way to capture leads, giving visibility and helping to build a loyal customer base.",
        },

        parcels: {
            title: "Instalments and charges",
            subTitle: "You can define a fixed amount to charge. " +
                "We provide some templates, but you can define your own.",
            warning: "An offer can only be deleted if there are no purchases for it.",

            form: {
                title: "Instalment template",

                name: {
                    label: "Offer name",
                    placeholder: "This will be available in your invoice"
                },

                chargeAmount: {
                    enable: "Generate my own parcels",

                }
            }
        },

        signature: {
            title: "Subscription Plans",
            subTitle:
                "You can create many offers and plans to each product. " +
                "The enabled offers will be available to the user at the Checkout.",
            form: {
                title: "Subscription model",
                name: {
                    label: "Plan name",
                    placeholder: "Plan name",
                },
                tag: {
                    label: "Highlight tag (optional)",
                    placeholder: "Recommended",
                },
                titlePlan: {
                    label: "Plan title",
                    placeholder: "Plan title",
                },
                normalPrice: {
                    label: "Normal price",
                    placeholder: "US$ 0,00",
                },
                promotionalPrice: {
                    label: "Promotional price (optional)",
                    placeholder: "US$ 0,00",
                },

                titleSwitch: "Preferences",
                priceFirstCharge: {
                    enable: "Different price on first charge",
                    label: "The customer will pay the first charge with a higher amount (membership) or lower amount (discount)",
                    placeholder: "US$ 0,00",
                },
                chargeAmount: {
                    enable: "Fixed number of charges",
                    label: "The customer will be charged a fixed amount of times",
                    placeholder: "Number fixed",
                },
            },
            warning: "A subscription can only be deleted if it has no active users",
        },

        price: {
            label: "Price",
            placeholder: "US$ 0,00",
        },

        currency: "Currency",

        back: {
            title: "Back Redirect",
            subTitle: "Redirect the buyer to the automatically registered URL when leaving checkout.",

            label: "Insert the link",
            placeholder: "https://example.com.br",
        },

        otherPage: {
            title: "Go to another page after approval",
            subTitle: "You can redirect the buyer to a personalized upsell or thank you page.",

            label: "Insert the link",
            placeholder: "https://example.com.br",
        },

        buttonCancel: "Cancel",
        buttonAction: "Add offer",
    },

    EmptyState: {
        label: "You haven't created any offers yet",
        content: "Create offers to boost your sales",
    },

    dataTable: {
        headers: {
            name: "Name",
            value: "Value",
            type: "Type",
            status: "Status",
            action: "Action",
        },

        type: {
            parcels: "Define parcels",
            onetime: "Single payment",
            month: "Monthly",
            quarter: "Quarterly",
            semi: "Semi-annual",
            year: "Annual",
        },

        editOffer: {
            title: "Edit the offer",
            subTitle: "Edit the offer information as desired.",
            buttonEdit: "Edit offer",
        },
    },

    deleteOffer: {
        title: "Delete offer",
        label: "Are you sure you want to delete the offer",
    },
};
