export const trackingCode = {
    title: "Tracking Codes",

    properties: {
        name: {
            label: "Descriptive name",
            placeholder: "Define some name to identify internally the tracking code",
        },
        code: {
            label: "Code",
            placeholder: "Platform provided code to track",
        },
        active: {
            label: "Active status",
            placeholder: "Define if this tracking code will be used at your checkout",
        },
        events: {
            label: "Events to trigger",
            subtitle: "Define and configure events related with the platform",

            triggers: {
                checkout: "Trigger event when Checkout is loaded (checkout_load)",
                paymentDetails: "Trigger event when Billing information is filled (payment_details)",
                purchase: "Trigger event when a purchase happens (purchase)",
            },

            advanced: {
                title: "Event properties",
                subtitle: "Define customized event triggers",

                triggerPurchaseInvoice: "Trigger Purchase event on Invoice",
                triggerPurchasePix: "Trigger Purchase event on Pix",
            },
        },
    },

    dataTable: {
        headers: {
            name: "Name",
            id: "ID",
            platform: "Platform",
            status: "Status",
            action: "Action",
        },
    },

    // TODO We will merge this with global actions object later
    actions: {
        search: "Search tracking codes",
        create: "Create",
        save: "Save",
        cancel: "Cancel",
        continue: "Continue",
        delete: {
            label: "Delete Tracking Code?",
            content: "This action is irreversible. Please confirm your intention.",
        },
    },

    // TODO We will merge this with global emptyState object later
    emptyState: {
        label: "There is nothing here",
        content: "You can create a new Tracking Code and starting your analytics",
    },

    offCanvas: {
        title: "Register Tracking Code",
        subTitle: "Select the platform you want to register.",
    },

    // createTrackingCode: {
    //     switchText: "Set the initial state of your tracking code.",

    //     name: {
    //         label: "Name",
    //         placeholder: "Enter your name",
    //     },
    //     code: {
    //         label: "Code",
    //         placeholder: "Example F-NGANOU",
    //     },

    //     settings: {
    //         title: "Configure tracking code events",
    //         subTitle: "Recording and optimizing conversions.",

    //         switchCheckout: "Start checkout (begin_checkout)",
    //         switchPayment: "Add payment details (add_payment_info)",
    //         switchPurchase: "Purchase",
    //         switchTrigger: "Trigger 'Purchase' event when generating invoice",
    //         switchPix: "Trigger 'Purchase' event when generating pix"
    //     },

    //     buttonCancel: "Cancel",
    //     buttonContinue: "Continue",
    //     buttonAction: "Add",
    // },

    // EmptyState: {
    //     label: "You haven't created any tracking code yet",
    //     content: "Create tracking codes",
    // },

    // dataTable: {
    //     headers: {
    //         name: "Name",
    //         id: "ID",
    //         platform: "Platform",
    //         status: "Status",
    //         action: "Action",
    //     },

    //     editTrackingCode: {
    //         title: "Edit the tracking pixel",
    //         subTitle: "Edit the tracking pixel information as desired.",
    //         buttonEdit: "Edit",
    //     }

    // },
};
