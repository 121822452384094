export const personalData = {
    titleData: 'Datos Personales',

    menu: {
        pf: 'Persona Natural',
        pj: 'Persona Jurídica',
        mei: 'MEI',
    },

    pf: {
        cpf: 'CPF',
        dateofbirth: 'Fecha de nacimiento',
        phone: 'Teléfono',
    },

    pj: {
        cnpj: 'CNPJ',
        cpf: 'CPF',
        phone: 'Teléfono',
    },

    mei: {
        cpf: 'CPF',
        cnpj: 'CNPJ',
        dateofbirth: 'Fecha de nacimiento',
        phone: 'Teléfono',
    },

    titleAddress: 'Dirección',

    address: {
        cep: {
            label: 'Código postal',
            placeholder: 'Ingresa tu código postal'
        },
        address: {
            label: 'Dirección',
            placeholder: 'Ingresa tu dirección'
        },
        number: {
            label: 'Número',
            placeholder: 'Ingresa tu número'
        },
        complement: {
            label: 'Complemento',
            placeholder: 'Ingresa tu complemento'
        },
        neighborhood: {
            label: 'Barrio',
            placeholder: 'Ingresa tu barrio'
        },
        city: {
            label: 'Ciudad',
            placeholder: 'Ingresa tu ciudad'
        },
        country: {
            label: 'País',
            placeholder: 'Ingresa tu país'
        },
        state: {
            label: 'Estado',
            placeholder: 'Ingresa tu estado'
        },
    },

    buttonText: 'Guardar cambios',

}
