import { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline/index.js";

import { XTreedMainLogo } from "@/components/Layout/XTreedMainLogo";
import NavigationMenu from "./NavigationMenu";

export const LateralMenu = () => {
    const getUserPreference = () => localStorage.getItem("menu-state") == "opened";

    const [isMenuExpanded, setMenuExpanded] = useState(getUserPreference());

    const toggleMenuExpansion = () => setMenuExpanded(!isMenuExpanded);

    useEffect(() => localStorage.setItem("menu-state", isMenuExpanded ? "opened" : "closed"), [isMenuExpanded]);

    const layoutClasses =
        "lg:flex relative transform-gpu transform-will-change flex-col hidden shadow-lg shadow-gray-300/50 z-20 bg-white";

    const transitionClasses = "transition-all duration-500";

    const expandedClasses = "w-56 delay-0";

    const retractedClasses = "w-16 delay-250";

    return (
        <div className={`${layoutClasses} ${transitionClasses} ${isMenuExpanded ? expandedClasses : retractedClasses}`}>
            <XTreedMainLogo extended={isMenuExpanded} />

            <NavigationMenu
                className={"flex flex-col flex-grow justify-center"}
                expand={isMenuExpanded}
            />

            <button
                onClickCapture={toggleMenuExpansion}
                className="bg-white text-black cursor-pointer flex items-center justify-center
                        border border-gray-300 rounded-full h-7 w-7 absolute top-4 -right-4"
            >
                <ChevronRightIcon
                    className={`${isMenuExpanded ? "rotate-180" : ""}
                        flex items-center w-3 h-3 text-gray-600
                        transform transition-all duration-500 ease-out`}
                />
            </button>
        </div>
    );
};
