export const personalData = {
    titleData: 'Personal Data',

    menu: {
        pf: 'Natural Person',
        pj: 'Juridical Person',
        mei: 'MEI',
    },

    pf: {
        cpf: 'CPF',
        dateofbirth: 'Date of birth',
        phone: 'Phone',
    },

    pj: {
        cnpj: 'CNPJ',
        cpf: 'CPF',
        phone: 'Phone',
    },

    mei: {
        cpf: 'CPF',
        cnpj: 'CNPJ',
        dateofbirth: 'Date of birth',
        phone: 'Phone',
    },

    titleAddress: 'Address',

    address: {
        cep: {
            label: 'Zip code',
            placeholder: 'Enter your zip code'
        },
        address: {
            label: 'Address',
            placeholder: 'Enter your Address'
        },
        number: {
            label: 'Number',
            placeholder: 'Enter your Number'
        },
        complement: {
            label: 'Complement',
            placeholder: 'Enter your Complement'
        },
        neighborhood: {
            label: 'Neighborhood',
            placeholder: 'Enter your Neighborhood'
        },
        city: {
            label: 'City',
            placeholder: 'Enter your City'
        },
        country: {
            label: 'Country of nature',
            placeholder: 'Enter your Country of nature'
        },
        state: {
            label: 'State',
            placeholder: 'Enter your State'
        },
    },

    buttonText: 'Save changes',

}
