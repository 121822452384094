export const notificationSettings = {
    title: "Configuración de notificaciones",
    subTitle: "Configura por separado tus preferencias de notificación por correo electrónico",
    salesManagement: {
        title: "Gestión de ventas",
        description: "Activar/desactivar la funcionalidad de gestión de ventas en el panel de control",
    },
    assessments: {
        title: "Evaluaciones",
        description: "Habilitar/deshabilitar notificaciones para reseñas",
    },

    affiliates: {
        title: "Afiliados",
        description: "Habilitar/deshabilitar notificaciones de nueva información o cambios en afiliados",
    },
    News: {
        title: "Noticias",
        description: "Habilitar/deshabilitar notificaciones de noticias y actualizaciones de la empresa",
    },

    buttonText: "Guardar cambios",
}
