import UserType from "@/types/UserType.jsx";

export class User {
  id;
  email;
  isCreator;
  personalProfile;
  address;
  bankAccounts;
  documents;

  static fromJson(json) {
    return new User({
      id: json.id,
      email: json.email,
      isCreator: json.is_creator,
      personalProfile: {
        fullName: json.profile?.personal?.full_name,
        publicName: json.profile?.personal?.public_name,
        website: json.profile?.personal?.website,
        description: json.profile?.personal?.description,
        avatar: json.profile?.personal?.avatar ?? null,
      },
      address: {
        address: json.profile?.address?.address,
        number: json.profile?.address?.number,
        complement: json.profile?.address?.complement,
        district: json.profile?.address?.district,
        city: json.profile?.address?.city,
        state: json.profile?.address?.state,
        country: json.profile?.address?.country,
        postal_code: json.profile?.address?.postal_code,
      },
      bankAccounts: json.profile?.bank_accounts ?? [],
      documents: {
        personalId: json.profile?.documents?.personal_id,
        proofOfResidence: json.profile?.documents?.proof_of_residence,
      },
    });
  }

  constructor(user) {
    if (!user) user = UserType;

    this.id = user.id;
    this.email = user.email;
    this.isCreator = user.isCreator;

    this.personalProfile = { ...UserType.personalProfile, ...user.personalProfile };
    this.address = { ...UserType.address, ...user.address };
    this.documents = { ...UserType.documents, ...user.documents };

    this.bankAccounts = user.bankAccounts ?? [];

    this.expiration = user.expiration || null;
  }

  /**
   * Define if current user has an avatar assigned
   * @returns Boolean
   */
  hasAvatar = () => this.personalProfile.avatar !== null;
}
