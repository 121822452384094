export const checkoutPage = {
    checkoutError: "Tuvimos algunos problemas con tu compra. Por favor, inténtalo de nuevo",
    backToProfile: "Regresar al perfil",
  
    purchaseDetails: {
      title: "Detalles de la compra",
      price: "Precio",
    },
  
    profile: {
      title: "Información personal",
    },
  
    trustworthy: {
      privacy: {
        title: "Privacidad",
        subtitle: "Tu información está 100% segura",
      },
      safety: {
        title: "Compra segura",
        subtitle: "Entorno protegido",
      },
      digital: {
        title: "Entrega a mi correo electrónico",
        subtitle: "Recibirás más información allí",
      },
      guaranteed: {
        title: "Garantizado",
        subtitle: "Contenido revisado y aprobado",
      },
    },
  
    payment: {
      title: "Información de pago",
  
      form: {
        name: {label: "Nombre completo", placeholder: "Díganos su nombre completo"},
        email: {label: "Su correo electrónico", placeholder: "Su mejor correo electrónico"},
        emailConfirmation: {label: "Confirme su correo electrónico", placeholder: "No olvide confirmarlo"},
        document: {label: "Número de documento", placeholder: "Solo números, por favor"},
        phone: {label: "Teléfono", placeholder: "Proporcionar su teléfono es opcional"},
        continue: "Continuar",
        payNow: "Pagar ahora"
      },
    },
  
    postPayment: {
      title: "¡Gracias por su compra!",
      subtitle: "Recibirás un correo electrónico con los detalles de la factura pronto.",
      action: "Comprar de nuevo",
      messages: {
        unknownError: {
          title: "Algo salió mal. Por favor, inténtalo de nuevo",
          details: "Tuvimos un problema procesando tu pago. Por favor, prueba otro método o contáctanos."
        },
        processing: {
          title: "¡Espera! Estamos procesando tu pago.",
          details: "Parece que tu método de pago necesita algo de tiempo para ser procesado por nuestra plataforma. " +
            "Te informaremos pronto enviándote un correo electrónico."
        },
        success: {
          title: "¡Pago exitoso!",
          details: "Recibirás un correo electrónico pronto con más información sobre tu compra",
        },
        paymentRequired: {
          title: "Algo ocurrió con tu pago",
          details: "Parece que tu pago no se realizó correctamente debido a tu método de pago. " +
            "Te sugerimos intentar nuevamente con otro método."
        },
      }
    }
  }
  