export const checkoutPage = {
    checkoutError: "Houve alguns problemas com o seu checkout. Por favor, tente novamente",
    backToProfile: "Voltar ao perfil",
  
    purchaseDetails: {
      title: "Detalhes da Compra",
      price: "Preço",
    },
  
    profile: {
      title: "Informações pessoais",
    },
  
    trustworthy: {
      privacy: {
        title: "Privacidade",
        subtitle: "Sua informação está 100% segura",
      },
      safety: {
        title: "Compra segura",
        subtitle: "Ambiente protegido",
      },
      digital: {
        title: "Entrega por e-mail",
        subtitle: "Você receberá mais informações lá",
      },
      guaranteed: {
        title: "Garantido",
        subtitle: "Conteúdo revisado e aprovado",
      },
    },
  
    payment: {
      title: "Informações de pagamento",
  
      form: {
        name: {label: "Nome completo", placeholder: "Informe seu nome completo"},
        email: {label: "Seu e-mail", placeholder: "Seu melhor e-mail"},
        emailConfirmation: {label: "Confirme seu e-mail", placeholder: "Não se esqueça de confirmá-lo"},
        document: {label: "Número do documento", placeholder: "Somente números, por favor"},
        phone: {label: "Telefone", placeholder: "Informar seu telefone é opcional"},
        continue: "Continuar",
        payNow: "Pagar agora"
      },
    },
  
    postPayment: {
      title: "Obrigado pela sua compra!",
      subtitle: "Você receberá um e-mail com os detalhes da fatura em breve.",
      action: "Comprar novamente",
      messages: {
        unknownError: {
          title: "Algo deu errado. Por favor, tente novamente",
          details: "Houve um problema ao processar seu pagamento. Por favor, tente outro método ou entre em contato conosco."
        },
        processing: {
          title: "Aguarde! Estamos processando seu pagamento.",
          details: "Parece que seu método de pagamento precisa de algum tempo para ser processado pela nossa plataforma. " +
            "Nós avisaremos em breve por e-mail."
        },
        success: {
          title: "Pagamento realizado com sucesso!",
          details: "Você receberá um e-mail em breve com mais informações sobre sua compra",
        },
        paymentRequired: {
          title: "Houve um problema com seu pagamento",
          details: "Parece que seu pagamento não foi concluído corretamente devido ao método de pagamento. " +
            "Sugerimos tentar novamente com outro método."
        },
      }
    }
  };
  