const UserType = {
  id: null,
  email: "",
  isCreator: false,
  personalProfile: {
    fullName: "",
    publicName: "",
    website: "",
    description: "",
    avatar: null,
  },
  address: {
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",

  },
  bankAccounts: [],
  documents: {
    personalId: null,
    proofOfResidence: null
  },
}

export default UserType;