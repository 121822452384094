export const header = {
    salutation: "Olá",
    text: "Bem-vindo de volta.",
    search: "Pesquisar...",

    notification: {

    },
    
    message: {

    },

    dropdown: {
        salesPerson: "Vendedor",
        student: "Estudante",
        myAccount: "Minha conta",
        mySales: "Minhas vendas",
        configuration: "Configurações",
        privacyPolicy: "Política de Privacidade",
        help: "Ajuda",
        exit: "Sair",
    }
}
