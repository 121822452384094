import { AuthenticationContext } from "@/contexts/AuthenticationContext";
import { useUserProfile } from "@/hooks/userProfile";
import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ element }) => {
  /** Get current authentication status from context */
  const { isAuthenticated } = useContext(AuthenticationContext);

  /** Function to fetch profile from API  */
  const { fetchUserProfile, fromLocalStorage } = useUserProfile();

  /** Async call to fetchUserProfile */
  const verifyUserProfile = async () => await fetchUserProfile();

  /** Defines if our user has valid session based on expiration time */
  const isValidSession = fromLocalStorage().expiration > Date.now();

  /**
   * If the user is not authenticated, redirect to login immediately
   */
  if (!isAuthenticated)
    return (
      <Navigate
        to="/"
        state={{ from: window.location.pathname }}
      />
    );

  /**
   * If we don't have a valid session, but user is authenticated, we'll fetch user
   * profile and verify if it's still logged in
   */

  if (!isValidSession) {
    verifyUserProfile()
      .catch(() => {
        return (
          <Navigate
            to="/"
            state={{ from: window.location.pathname }}
          />
        )
      })
  }

  return element
};
