export const availableMenuAccount = {
    profile: {
        name: "Perfil",
        text: "Adicionar texto",
    },
    personalData: {
        name: "Dados Pessoais",
        text: "Adicionar texto",
    },
    documents: {
        name: "Documentos",
        text: "Adicionar texto",
    },
    financialData: {
        name: "Dados Financeiros",
        text: "Adicionar texto",
    },
    security: {
        name: "Segurança",
        text: "Adicionar texto",
    },
};
