import HeaderWithDivider from "@comp/Typography/HeaderWithDivider.jsx";

export function TextWithIcon({children, icon, className = "", iconClass, showDivider = false}) {
  return (
    <HeaderWithDivider
      className={`flex flex-row items-center gap-2 ${className}`}
      showDivider={showDivider}>

      <img src={icon} alt="" className={`text-gray-500 fill-gray-500 ${iconClass}`} />
      {children}
    </HeaderWithDivider>
  )
}