export const security = {
    title: "Segurança e privacidade",

    page: {
        changePassword: {
            title: "Alterar Senha",
            text: "Crie uma nova senha para manter sua conta segura.",
            buttonText: "Alterar Senha",
        },
        privacyCenter: {
            title: "Centro de Privacidade",
            text: "Acesse suas informações pessoais em conformidade com as leis e regulamentos de proteção de dados. Saiba mais acessando nossa Política de Privacidade e o Termo de Tratamento de Dados Pessoais.",
            buttonText: "Receber por e-mail",
        },
        connectedDevices: {
            title: "Dispositivos Conectados",
            text: "Desconecte todos os dispositivos conectados à sua conta XTreed",
            buttonText: "Desconectar todos os dispositivos",
        },
        closeAccount: {
            title: "Fechar Conta",
            text: "Feche sua conta XTreed",
            buttonText: "Fechar conta"
        },
    },

    offCanvas: {
        title: "Alterar Senha",

        subTitle: "Aqui você pode criar uma nova senha de acesso.",

        partialChangePassword: {
            currentPassword: {
                label: "Senha atual",
                placeholder: "Digite sua senha atual",
            },
            newPassword: {
                label: "Nova Senha",
                placeholder: "Digite sua nova senha",
            },
            confirmPassword: {
                label: "Confirmar Senha",
                placeholder: "Confirme sua nova senha",
            },

            buttonCancel: "Cancelar",

            buttonSave: "Salvar"
        },
    },

    modalAction: {
        title: "Confirmação",
        label: "Você realmente deseja desconectar este e todos os outros dispositivos conectados à sua conta?",
    },

    modalDelete: {
        title: "Fechar a conta",
        label: "Você tem certeza de que deseja fechar sua conta XTreed?",
    },
}
