export const security = {
    title: "Seguridad y privacidad",

    page: {
        changePassword: {
            title: "Cambiar Contraseña",
            text: "Crea una nueva contraseña para mantener tu cuenta segura.",
            buttonText: "Cambiar Contraseña",
        },
        privacyCenter: {
            title: "Centro de Privacidad",
            text: "Accede a tu información personal en cumplimiento con las leyes y regulaciones de protección de datos. Obtén más información accediendo a nuestra Política de Privacidad y al Término de Procesamiento de Datos Personales.",
            buttonText: "Recibir por correo electrónico",
        },
        connectedDevices: {
            title: "Dispositivos conectados",
            text: "Desconecta todos los dispositivos conectados a tu cuenta de XTreed",
            buttonText: "Desconectar todos los dispositivos",
        },
        closeAccount: {
            title: "Cerrar la cuenta",
            text: "Cierra tu cuenta de XTreed",
            buttonText: "Cerrar cuenta"
        },
    },

    offCanvas: {
        title: "Cambiar Contraseña",

        subTitle: "Aquí puedes crear una nueva contraseña de acceso.",

        partialChangePassword: {
            currentPassword: {
                label: "Contraseña actual",
                placeholder: "Introduce tu contraseña actual",
            },
            newPassword: {
                label: "Nueva Contraseña",
                placeholder: "Introduce tu nueva contraseña",
            },
            confirmPassword: {
                label: "Confirmar Contraseña",
                placeholder: "Confirma tu nueva contraseña",
            },

            buttonCancel: "Cancelar",

            buttonSave: "Guardar"
        },
    },

    modalAction: {
        title: "Confirmación",
        label: "¿Realmente deseas desconectar este y todos los demás dispositivos conectados a tu cuenta?",
    },

    modalDelete: {
        title: "Cerrar la cuenta",
        label: "¿Estás seguro de que deseas cerrar tu cuenta de XTreed?",
    },
}
