export const notification = {
    title: "Notifications",
    subTitle: "All your notifications",

    search: "Search notifications",

    atualization: {
        title: "Data update",
        subTitle: "Don't forget to participate in our",
    },
    announcement: {
        title: "Announcement of new integration",
        subTitle: "Our Management Control Panel",
    },
    newRequest: {
        title: "New request",
        subTitle: "James Eusébio placed a new order for Blue",
    },

    buttonText: "Show all notifications",
}