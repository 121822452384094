export const accountProfile = {
    title: "Profile",

    form: {
        fullName: {
            label: "Full name (individual)",
            placeholder: "Enter your full name",
        },
        email: {
            label: "Email",
            placeholder: "Type your e-mail",
        },
        publicName: {
            label: "Public Name",
            placeholder: "Enter your public name",
        },
        website: {
            label: "Website",
            placeholder: "Enter your website",
        },
        description: {
            label: "Description",
            // placeholder: "Enter your description",
        },
    },

    sessionTitle: "Public data",

    buttonText: "Save editions",

}