import { lazy } from "react";

const HomePage = lazy(() => import("@/pages/Website/Homepage"));
const LoginPage = lazy(() => import("@/pages/Auth/LoginPage"));
const RegisterAccount = lazy(() => import("@/pages/Auth/RegisterPage"));
const ResetPassword = lazy(() => import("@/pages/Auth/ResetPasswordPage"));
const ForgotPassword = lazy(() => import("@/pages/Auth/ForgotPasswordPage"));
const CheckoutBuilder = lazy(() => import("@/pages/Checkout/CheckoutBuilder"));
const PrivacyPolicyPage = lazy(() => import("@/pages/PrivacyPolicy/PrivacyPolicyPage.jsx"));

export const PublicRoutes = [
    {
        path: "/",
        default: true,
        element: <HomePage />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/register",
        element: <RegisterAccount />,
    },
    {
        path: "/resetPassword",
        element: <ResetPassword />,
    },
    {
        path: "/forgotPassword",
        element: <ForgotPassword />,
    },
    {
        path: "/privacy-policy",
        element: <PrivacyPolicyPage />,
    },
    {
        path: "/checkout/:offerId",
        element: <CheckoutBuilder />,
    },
];
