export const dashboard = {
    banner: {
        title: "¡Una plataforma completa y gratuita para tener tu negocio en línea!",
        subtitle: "Convierte tu idea en realidad: Emprende en línea con nuestra plataforma completa y gratuita.",
    },
    chart: {
        newRevenue: "Nuevos ingresos",
        totalRevenue: "Ingresos totales",
        salesAverage: "Promedio de ventas",
        lastWeek: "de la semana pasada",
    },
}
