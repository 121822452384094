import { AvailableMenus } from "@/data/Menu.js";
import NavigationItem from "./NavigationItem.jsx";

const NavigationMenu = props => {
    const pageActive = "dashboard";

    const renderAvailableMenus = () =>
        AvailableMenus.map(menu => (
            <NavigationItem
                key={menu.id}
                menu={menu}
                expanded={props.expand}
                isActive={pageActive === menu.target}
            />
        ));

    return <nav className={props.className}>{renderAvailableMenus()}</nav>;
};

export default NavigationMenu;
