export const availableMenuAccount = {

    profile: {
        name: "Perfil",
        text: "Agregar texto",
    },
    personalData: {
        name: "Datos Personales",
        text: "Agregar texto"
    },
    documents: {
        name: "Documentos",
        text: "Agregar texto",
    },
    financialData: {
        name: "Datos Financieros",
        text: "Agregar texto",
    },
    security: {
        name: "Seguridad",
        text: "Agregar texto",
    },
}
