import { arrayToObject, filterNullValues } from "@/utils/validations";
import axios from "axios";

export default class RequestService {
  constructor() {
    axios.defaults.withCredentials = true;

    this.axios = axios.create();
    this.axios.interceptors.response.use(result => result, this.verifyUnauthenticated);
  }

  /**
   * Every time we have a failed request this interceptor should
   * verify if Status Code is UNAUTHORIZED and redirect to login
   */
  verifyUnauthenticated(error) {
    if (error.response?.status === 401) {
      localStorage.clear();

      // TODO This must change to redirect user where it came from
      window.location.pathname = "/";

      return Promise.reject();
    }

    return Promise.reject(error);
  }

  filterRequest(data) {
    return arrayToObject(filterNullValues(data));
  }
}
