import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import i18nEnglish from "@lang/en";
import i18nSpanish from "@lang/es";
import i18nPortuguese from "@lang/pt";

// handle language resources
const resources = {
  en: { translation: i18nEnglish },
  es: { translation: i18nSpanish },
  pt: { translation: i18nPortuguese },
};

const getInitialLanguage = () => localStorage.getItem('language') || navigator.language || 'en';

const initSettings = {
  resources,
  lng: getInitialLanguage(),
  debug: true,
  interpolation: {
    escapeValue: false,
  },
};

i18n
  .use(initReactI18next)
  .init(initSettings);

export default i18n;
