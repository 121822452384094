export const offers = {
    title: "Ofertas",

    search: "Buscar ofertas...",
    buttonText: "+ Agregar ofertas",

    offCanvas: {
        title: "Crear oferta",
        subTitle: "Configura y nombra la nueva oferta.",
    },

    createOffer: {
        switchText: "Define el estado inicial de tu oferta.",

        titleType: "Seleccione el tipo de oferta",

        type: {
            singlePrice: "Precio único",
            subscription: "Suscripción",
        },

        name: {
            label: "Nombre de la oferta",
            placeholder: "Ingresa el nombre de la oferta",
        },

        freeState: {
            text: "Activar oferta gratuita",
            warningFree: "Los productos gratuitos son una excelente manera de captar clientes potenciales, brindando visibilidad y ayudando a construir una base de clientes leales.",
        },

        signature: {
            title: "Planes de suscripción",
            subTitle: "Puedes crear una oferta con uno o más planes. Estas opciones estarán disponibles para el comprador en el proceso de pago.",
            form: {
                title: "Plan",
                name: {
                    label: "Nombre del plan",
                    placeholder: "Nombre del plan",
                },
                tag: {
                    label: "Etiqueta destacada (opcional)",
                    placeholder: "Recomendado",
                },
                titlePlan: {
                    label: "Título del plan",
                    placeholder: "Título del plan",
                },
                normalPrice: {
                    label: "Precio normal",
                    placeholder: "US$ 0,00",
                },
                promotionalPrice: {
                    label: "Precio promocional (opcional)",
                    placeholder: "US$ 0,00",
                },

                titleSwitch: "Preferencias",
                switchFisrtCharge: {
                    text: "Precio diferente en el primer cargo",
                    label: "El cliente pagará el primer cargo con una cantidad mayor (membresía) o menor (descuento)",
                    placeholder: "US$ 0,00",
                },
                switchFixedCharge: {
                    text: "Número fijo de cargos",
                    placeholder: "Número fijo"
                }
            },
            warning: "Una suscripción solo puede ser eliminada si no tiene usuarios activos",
        },

        price: {
            label: "Precio",
            placeholder: "US$ 0,00",
        },

        currency: "Moneda",

        back: {
            title: "Redirección de regreso",
            subTitle: "Redirige al comprador a la URL registrada automáticamente al salir del proceso de pago.",

            label: "Inserta el enlace",
            placeholder: "https://example.com.br",
        },

        otherPage: {
            title: "Ir a otra página después de la aprobación",
            subTitle: "Puedes redirigir al comprador a una página de upsell personalizada o de agradecimiento.",

            label: "Inserta el enlace",
            placeholder: "https://example.com.br",
        },

        buttonCancel: "Cancelar",
        buttonAction: "+ Agregar oferta",
    },

    EmptyState: {
        label: "Aún no has creado ninguna oferta",
        content: "Crea ofertas para aumentar tus ventas",
    },

    dataTable: {
        headers: {
            name: "Nombre",
            value: "Valor",
            type: "Tipo",
            status: "Estado",
            action: "Acción",
        },

        type: {
            onetime: "A contado",
            month: "Mensual",
            quarter: "Trimestral",
            semi: "Semestral",
            year: "Anual",
        },

        editOffer: {
            title: "Editar la oferta",
            subTitle: "Edita la información de la oferta según desees.",
            buttonEdit: "Editar oferta",
        }
    },

    deleteOffer: {
        title: "Eliminar oferta",
        label: "¿Estás seguro de que quieres eliminar la oferta?",
    },
}
