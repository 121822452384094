import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const NavigationItem = ({ menu, expanded = true }) => {
    const { t } = useTranslation();

    const hideMenuLabel = "scale-x-0 opacity-0";

    const showMenuLabel = "scale-x-100 opacity-100 delay-200";

    return (
        <NavLink
            to={menu.target}
            title={t(menu.name)}
            unstable_viewTransition
            className={`nav-menu group flex items-center py-5 px-5 cursor-pointer transition
             duration-500 ease-in-out group hover:bg-green-500 text-gray-700 fill-gray-600 stroke-gray-600`}
        >
            <div className="w-fit flex flex-col group-hover:stroke-white group-hover:fill-white">{menu.icon}</div>

            <div
                className={`transition duration-250 origin-left overflow-clip ${expanded ? showMenuLabel : hideMenuLabel}`}
            >
                <span className="pl-2 transition text-md group-hover:text-white">{t(menu.name)}</span>
            </div>
        </NavLink>
    );
};

export default NavigationItem;
