export const security = {
    title: "Security and privacy",

    page: {
        changePassword: {
            title: "Change Password",
            text: "Create a new password to keep your account secure.",
            buttonText: "Change Password",
        },
        privacyCenter: {
            title: "Privacy Center",
            text: "Access your personal information in compliance with data protection laws and regulations. Find out more by accessing our Privacy Policy and the Personal Data Processing Term.",
            buttonText: "Receive by email",
        },
        connectedDevices: {
            title: "Connected devices",
            text: "Disconnect all devices connected to your XTreed account",
            buttonText: "Disconnect all devices",
        },
        closeAccount: {
            title: "Close the account",
            text: "Close your XTreed account",
            buttonText: "Close account"
        },
    },

    offCanvas: {
        title: "Change Password",

        subTitle: "Here you can create a new access password.",

        partialChangePassword: {
            currentPassword: {
                label: "Current password",
                placeholder: "Enter your current password",
            },
            newPassword: {
                label: "New Password",
                placeholder: "Confirm your new password",
            },
            confirmPassword: {
                label: "Confirm Password",
                placeholder: "Confirm your new password",
            },

            buttonCancel: "Cancel",

            buttonSave: "Save"
        },
    },

    modalAction: {
        title: "Confirmation",
        label: "Do you really want to disconnect this and all other devices connected to your account?",
    },

    modalDelete: {
        title: "Close the account",
        label: "Are you sure you want to close your XTreed account?",
    },
}