export const offers = {
    title: "Ofertas",

    search: "Buscar ofertas...",
    buttonText: "+ Adicionar Ofertas",

    offCanvas: {
        title: "Criar oferta",
        subTitle: "Configure e nomeie a nova oferta.",
    },

    createOffer: {
        switchText: "Defina o estado inicial da sua oferta.",

        titleType: "Selecione o tipo de oferta",

        type: {
            singlePrice: "Preço Único",
            subscription: "Subscrição",
        },

        name: {
            label: "Nome da oferta",
            placeholder: "Digite o nome da oferta",
        },

        freeState: {
            text: "Ativar oferta gratuita",
            warningFree: "Produtos gratuitos são uma ótima maneira de capturar leads, oferecendo visibilidade e ajudando a construir uma base de clientes fiel.",
        },

        signature: {
            title: "Planos de Assinatura",
            subTitle: "Você pode criar uma oferta com um ou mais planos. Essas opções estarão disponíveis para o comprador no Checkout.",
            form: {
                title: "Plano",
                name: {
                    label: "Nome do plano",
                    placeholder: "Nome do plano",
                },
                tag: {
                    label: "Etiqueta de destaque (opcional)",
                    placeholder: "Recomendado",
                },
                titlePlan: {
                    label: "Título do plano",
                    placeholder: "Título do plano",
                },
                normalPrice: {
                    label: "Preço normal",
                    placeholder: "R$ 0,00",
                },
                promotionalPrice: {
                    label: "Preço promocional (opcional)",
                    placeholder: "R$ 0,00",
                },

                titleSwitch: "Preferências",
                switchFisrtCharge: {
                    text: "Preço diferente na primeira cobrança",
                    label: "O cliente pagará um valor maior (assinatura) ou menor (desconto) na primeira cobrança",
                    placeholder: "R$ 0,00",
                },
                switchFixedCharge: {
                    text: "Número fixo de cobranças",
                    placeholder: "Número fixo"
                }
            },
            warning: "Uma assinatura só pode ser excluída se não tiver usuários ativos",
        },

        price: {
            label: "Preço",
            placeholder: "R$ 0,00",
        },

        currency: "Moeda",

        back: {
            title: "Redirecionamento após saída",
            subTitle: "Redirecione o comprador para a URL registrada automaticamente ao sair do checkout.",

            label: "Inserir o link",
            placeholder: "https://example.com.br",
        },

        otherPage: {
            title: "Ir para outra página após aprovação",
            subTitle: "Você pode redirecionar o comprador para uma página personalizada de upsell ou agradecimento.",

            label: "Inserir o link",
            placeholder: "https://example.com.br",
        },

        buttonCancel: "Cancelar",
        buttonAction: "+ Adicionar oferta",
    },

    EmptyState: {
        label:"Você ainda não criou nenhuma oferta",
        content:"Crie Ofertas para aumentar suas vendas",
    },

    dataTable: {
        headers: {
            name: "Nome",
            value: "Valor",
            type: "Tipo",
            status: "Status",
            action: "Ação",
        },

        type: {
            onetime: "A vista",
            month: "mensal",
            quarter: "trimestral",
            semi: "semestral",
            year: "anual",
        },

        editOffer: {
            title: "Editar a oferta",
            subTitle: "Edite as informações da oferta conforme desejar.",
            buttonEdit: "Editar oferta",
        }
    },

    deleteOffer: {
        title: "Excluir oferta",
        label: "Tem certeza de que deseja excluir a oferta",
    },
}
