export const products = {
    buttonCreate: '+ Adicionar produto',

    offCanvas: {
        title: 'Novo Produto',
        subTitle: 'Configure e nomeie o novo produto.',
    },

    productsGallery: {
        titleSales: 'Produtos',
        titleBuyer: 'Meus Produtos',
    },

    productCard: {
        ProductCardContentSales: {
            students: 'Estudantes',
            modules: 'Módulos',
        }
    }
}
