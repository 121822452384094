export const coupons = {
    title: "Cupons",

    page: {
        search: "Buscar produtos",
        buttonCreate: "Adicionar cupom",
    },

    EmptyState: {
        label: "Você ainda não criou nenhum desconto",
        content: "Crie descontos para aumentar suas vendas",
    },

    dataTable: {
        headers: {
            name: "Nome",
            coupon: "Cupom",
            code: "Código",
            status: "Status",
            action: "Ação",
        },
    },

    offCanvas: {
        title: "Novo cupom",
        subTitle: "Configure um cupom de desconto e aumente as conversões da sua loja, atraia novos compradores e incentive a conclusão da compra."
    },

    createCoupon: {
        switchText: 'Defina o estado inicial do seu cupom.',
        name: {
            label: 'Nome',
            placeholder: 'Digite um nome',
        },
        codeCoupon: {
            label: 'Código do Cupom',
            placeholder: 'Cupom',
        },

        text: 'Regras para aplicação do cupom',

        rules: {
            value: 'Valor em R$',
            percent: 'Porcentagem em %',
        },
        discount: {
            label: 'Desconto de',
            placeholder: 'R$ 0,00',
        },
        dueDate: {
            switchText: 'Não expira',
            label: 'Válido até',
            placeholder: '00/00/0000',
        },
        noLimit: {
            switchText: 'Sem limite',
            label: 'Limite de uso',
            placeholder: '0',
        },

        buttonCancel: 'Cancelar',

        buttonAction: 'Adicionar Cupom',
    },
    
    editCoupon: {
        title: 'Editar o cupom',
        subTitle: 'Edite as informações da oferta conforme desejar.',
        buttonEdit: 'Editar Cupom',
    },

    DeleteCoupon: {
        title: 'Excluir cupom',
        label: 'Você tem certeza de que deseja excluir o cupom',
    }
};
