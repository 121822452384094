export function CardWrapper(
  {
    margins = "normal",
    children,
    onClick,
    className = "flex-col",
    ref = null,
  }
) {
  function cardSpacing() {
    if (margins === "tight")
      return "px-4 py-2";

    if (margins === "none")
      return "px-0 py-0";

    if (margins === "normal-square")
      return "p-5"

    return "px-8 py-4";
  }

  return (
    <div
      ref={ref}
      className={`flex gap-3 rounded-lg w-full shadow-lg bg-white ${cardSpacing()} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}