export const dashboard = {
    banner: {
        title: "A complete and free platform for you to have your business online!",
        subtitle: "Turn your idea into reality: Undertake online with our complete and free platform!",
    },
    chart: {
        newRevenue: "New revenue",
        totalRevenue: "Total Revenue",
        salesAverage: "Sales average",
        lastWeek: "from last week",
    },
}

