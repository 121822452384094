export const trackingCode = {
    title: "Códigos de Rastreo",

    properties: {
        name: {
            label: "Nombre descriptivo",
            placeholder: "Este nombre será apenas para descripción interna",
        },
        code: {
            label: "Código",
            placeholder: "El código fornecido por la plataforma de rastreo",
        },
        active: {
            label: "Status de activo",
            placeholder: "Define el status de activo/inactivo del código",
        },
        events: {
            label: "Eventos gatillo",
            subtitle: "Configura eventos relacionados a la plataforma",

            triggers: {
                checkout: "Disparar evento cuando Checkout fue inicializado (checkout_load)",
                paymentDetails: "Disparar evento cuando rellenar informaciones de facturamento (payment_details)",
                purchase: "Disparar evento cuando uma compra ocurre (purchase)",
            },

            advanced: {
                title: "Propiedades avanzadas",
                subtitle: "Define gatillos especiales para seguimiento",

                triggerPurchaseInvoice: "Disparar evento de compra al generar cupon de pago",
                triggerPurchasePix: "Disparar evento de compra con Pix",
            },
        },
    },

    dataTable: {
        headers: {
            name: "Nombre",
            id: "ID",
            platform: "Plataforma",
            status: "Status",
            action: "Acciones",
        },
    },

    actions: {
        search: "Buscar código de rastreo",
        create: "Crear",
        save: "Salvar",
        cancel: "Cancelar",
        continue: "Continuar",
        delete: {
            label: "Excluir código de rastreo?",
            content: "Esta acción no se puede deshacer. Por favor, confirme su intención.",
        },
    },

    emptyState: {
        label: "No hay códigos de rastreo cadastrados",
        content: "Crea códigos para conocer a tu audiencia y registrar su comportamiento",
    },

    offCanvas: {
        title: "Crear código de rastreo",
        subTitle: "Elija la plataforma de rastreo",
    },
};
