export const DollarSign = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6119 8.08984C9.98769 8.08984 9.42188 8.63173 9.42188 9.36984C9.42188 9.87252 9.53009 10.1024 9.62588 10.2246C9.72842 10.3554 9.90085 10.4716 10.2403 10.5922L14.2724 11.9929C14.7216 12.1521 15.2086 12.3907 15.5684 12.8496C15.9351 13.3174 16.0919 13.9125 16.0919 14.6298C16.0919 16.1317 14.9177 17.4098 13.4019 17.4098H10.8919C9.2137 17.4098 7.92188 15.9993 7.92188 14.3298C7.92188 13.9156 8.25766 13.5798 8.67188 13.5798C9.08609 13.5798 9.42188 13.9156 9.42188 14.3298C9.42188 15.2404 10.11 15.9098 10.8919 15.9098H13.4019C14.0261 15.9098 14.5919 15.368 14.5919 14.6298C14.5919 14.1272 14.4837 13.8972 14.3879 13.775C14.2853 13.6442 14.1129 13.5281 13.7735 13.4075L9.74132 12.0068C9.29218 11.8476 8.80512 11.609 8.44537 11.15C8.07866 10.6822 7.92188 10.0872 7.92188 9.36984C7.92188 7.86796 9.09606 6.58984 10.6119 6.58984H13.1219C14.8 6.58984 16.0919 8.00043 16.0919 9.66984C16.0919 10.0841 15.7561 10.4198 15.3419 10.4198C14.9277 10.4198 14.5919 10.0841 14.5919 9.66984C14.5919 8.75926 13.9037 8.08984 13.1219 8.08984H10.6119Z"
                stroke="none"
                fill="inherit"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
                stroke="none"
                fill="inherit"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
                stroke="none"
                fill="inherit"
            />
        </svg>
    );
};
