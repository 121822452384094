export const dashboard = {
    banner: {
        title: "Uma plataforma completa e gratuita para você levar seu negócio online!",
        subtitle: "Transforme sua ideia em realidade: Empreenda online com nossa plataforma completa e gratuita!",
    },
    chart: {
        newRevenue: "Nova receita",
        totalRevenue: "Receita total",
        salesAverage: "Média de vendas",
        lastWeek: "da semana passada",
    },
}
