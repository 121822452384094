const base = import.meta.env.VITE_API_URL;

export const endpoints = {
    csrf: `${base}/sanctum/csrf-cookie`,
    login: `${base}/login`,
    register: `${base}/register`,
    forgotPassword: `${base}/forgot-password`,
    resetPassword: `${base}/reset-password`,
    logout: `${base}/logout`,
    user: `${base}/api/user`,

    dashboard: `${base}/api/dashboard`,
    productsIndex: `${base}/api/products`,
    productsDetail: `${base}/api/products/:uuid`,
    productsTrackingCode: `${base}/api/products/:uuid/tracking-code`,
    productsCoupons: `${base}/api/products/:uuid/coupons`,
    productsOffers: `${base}/api/products/:uuid/offers`,
    productsAffiliate: `${base}/api/products/:uuid/affiliate`,
    documents: `${base}/api/user/documents`,
    avatar: `${base}/api/user/avatar`,
    notificationSettings: `${base}/api/notifications/settings`,

    products: {
        checkout_settings: `${base}/api/products/:uuid/checkout-settings`
    },

    checkout: {
        settings: `${base}/api/checkout/:uuid/settings`,
        validateCoupon: `${base}/api/checkout/:product/coupon/:code`,
        stripe: {
            createPaymentIntent: `${base}/api/checkout/{uuid}/payment-intent`,
            createCustomer: `${base}/api/checkout/customer`,
        },
    },

    storage: `${base}/storage/`,
    base: base,
};
