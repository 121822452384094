export const coupons = {
    title: "Coupons",

    page: {
        search: "Search products",
        buttonCreate: "Add coupon",
    },

    EmptyState: {
        label: "You haven't created any discounts yet",
        content: "Create discounts to boost your sales",
    },

    dataTable: {
        headers: {
            name: "Name",
            coupon: "Coupon",
            code: "Code",
            status: "Status",
            action: "Action",
        },
    },

    offCanvas: {
        title: "New coupon",
        subTitle: "Set up a discount coupon and increase your store's conversions, attract new buyers and encourage completion of the purchase."
    },

    createCoupon: {
        switchText: 'Set the initial state of your coupon.',
        name: {
            label: 'Name',
            placeholder: 'Enter a name',
        },
        codeCoupon: {
            label: 'Coupon Code',
            placeholder: 'Coupon',
        },

        text: 'Rules for applying coupon',

        rules: {
            value: 'Value in US$',
            percent: 'Percentage in %',
        },
        discount: {
            label: 'Discount from',
            placeholder: 'US$ 0,00',
        },
        dueDate: {
            switchText: 'Not due',
            label: 'Due in',
            placeholder: '00/00/0000',
        },
        noLimit: {
            switchText: 'There is no limit',
            label: 'Usage limit',
            placeholder: '0',
        },

        buttonCancel: 'Cancel',

        
        buttonAction: 'Add Coupon',
    },
    
    editCoupon: {
        title: 'Edit the coupon',
        subTitle: 'Edit the offer information as desired.',
        buttonEdit: 'Edit Coupon',
    },

    DeleteCoupon: {
        title: 'Delete coupon',

        label: 'Are you sure you want to delete the Coupon',

    }

}