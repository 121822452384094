export const checkoutPage = {
  checkoutError: "We had some problems with your checkout. Please try again",

  purchaseDetails: {
    title: "Purchase details",

    // TODO Some of the keys here can me merge with product details, such as price, currency and others
    price: "Price",
  },

  coupon: {
    call_to_action: 'Do you have a coupon code?',
    placeholder: 'You can type it here',
    action: 'Apply coupon'
  },

  profile: {
    title: "Personal information",
  },

  trustworthy: {
    privacy: {
      title: "Privacy",
      subtitle: "Your information is 100% secure with us",
    },
    safety: {
      title: "Safe purchasing",
      subtitle: "Secured environment to keep you safe",
    },
    digital: {
      title: "E-mail delivery",
      subtitle: "You will receive more information in your mailbox",
    },
    guaranteed: {
      title: "Guaranteed",
      subtitle: "Content reviewed and approved by our team",
    },
  },

  payment: {
    title: "Payment information",

    address: {
      title: "Address information",
      address: {
        label: "Main Address",
        placeholder: "My street name",
      },
      number: {
        label: "Number",
        placeholder: "123",
      },
      complement: {
        label: "Complement",
        placeholder: "Some random instruction",
      },
      city_state: {
        label: "City, State and Country",
        placeholder: "City, State - US",
      },
    },

    form: {
      name: {
        label: "Full name",
        placeholder: "Tell us your full name",
      },
      email: {
        label: "Your e-mail",
        placeholder: "Your best e-mail",
      },
      emailConfirmation: {
        label: "Confirm your e-mail",
        placeholder: "Don't forget to confirm your e-mail",
      },
      document: {
        label: "Document number",
        placeholder: "Only numbers, please",
      },
      phone: {
        label: "Phone",
        placeholder: "Giving your phone is optional",
      },
      continue: "Continue",
      payNow: "Pay now",
      previous: "Previous step",
    },
  },

  postPayment: {
    title: "Thank you for your purchase!",
    subtitle: "You will receive an e-mail with invoice details soon.",
    action: "Buy again",
    messages: {
      // TODO Move this string to own namespace Errors
      unknownError: {
        title: "Something went wrong. Please try again",
        details: "We had some problem processing your payment. Please try another method or contact us.",
      },
      processing: {
        title: "Hold on! We're processing your payment.",
        details:
          "Seems that your payment method need some time to be processed by our platform. " +
          "We'll let you know soon by sending an e-mail.",
      },
      success: {
        title: "Payment succeeded!",
        details: "You will receive an e-mail soon with more information about your purchase",
      },
      paymentRequired: {
        title: "Something happened with your payment",
        details:
          "Seems that your payment did not occur well because of your payment method. " +
          "We suggest you to try again with another one.",
      },
    },
  },
};
